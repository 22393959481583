.icon-wrapper {
    background-color: var(--primary-color) ;/* Orange background */
    padding: 20px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .card {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    border: none;
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-title {
    font-weight: bold;
    margin-top: 15px;
  }
  
  h2 {
    font-weight: bold;
  }
  