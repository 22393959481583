/* Infinite Scrolling Text */
.scrolling-container {
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 0;
}

#scrolling-text {
    display: inline-block;
    padding-left: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    will-change: transform;
    color: white;
    font-size: 16px;
    white-space: nowrap;
}
