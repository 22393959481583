/* colors.css */
:root {
    --primary-color: #2f2f80;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --background-color: #f5f5f5;
    --text-color: #333333;
    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #95a5a6;
  }
  