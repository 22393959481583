/* Add green background color for mobile view */


.custom-navlink:hover {
    color: #f26522; /* White text color on hover */
    text-decoration: underline;
    text-underline-offset: 10px; 
  }


@media (max-width: 991px) {
    .custom-navbar {
      background-color: #28a745;  /* Green background color for mobile */
    }

    .custom-navbar-logo{
        width: 50px;
        height: auto;
    }


     /* Attractive nav link styling */
  .custom-navlink {
    font-weight: bold;
    padding: 10px 15px;
    color: #fff;
    background-color: #28a745; 
    transition: color 0.3s ease-in-out;
    text-align: left;
   
  }
  
  .custom-navlink:hover {
    color: #fff; /* White text color on hover */
    text-decoration: underline;
  }
  }
  
 
  
  /* Align navbar links to the left for mobile */
  @media (max-width: 991px) {
    .navbar-collapse {
      text-align: left; /* Align nav links to the left in mobile view */
    }
  }
  
  /* Adjust font size for smaller screens */
  @media (max-width: 576px) {
    .custom-navlink {
      font-size: 14px;
    }
  }
  