.contact-form-card {
    border-radius: 5px;
    background-color: #f8f9fa;
    /* padding: 20px; */
    margin-top: 20px;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .contact-form-card:hover {
    transform: translateY(-10px); /* Adding hover effect on the card */
  }

  
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control {
    border-radius: 5px;
    box-shadow: none;
    border: 2px solid #ced4da;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .form-control:focus {
    border-color: #28a745; /* Green outline on focus */
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
  }
  
  .submit-button {
    background-color: #28a745; /* Primary color (green) */
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    width: 100%; /* Full-width button */
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .submit-button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
  
  .submit-button:active {
    background-color: #1e7e34; /* Even darker green on click */
    transform: scale(1); /* Remove zoom on click */
  }
  
  .contact-form-card .form-group {
    margin-bottom: 20px;
  }
  