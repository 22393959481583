/* FloatingComplaintButton.css */
.floating-button {
    position: fixed;
    bottom: 100px;
    right: 0px; /* Position at the right edge of the screen */
    transform: translateY(-100%) rotate(90deg); /* Center vertically and rotate the button */
    z-index: 100;
}

.floating-button button {
    padding: 10px 20px;
    background-color: #3F51B5; /* Blue color */
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-button button:hover {
    background-color: #303F9F;
}
