.address-card {
    background-color: #014D1B; /* Dark green */
    color: white;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .address-card .card-body {
    padding: 20px;
  }
  
  .address-section, 
  .contact-section, 
  .email-section, 
  .hours-section {
    margin-bottom: 20px;
  }
  
  .address-section h5,
  .contact-section h5,
  .email-section h5,
  .hours-section h5 {
    margin-bottom: 10px;
    
  }
  
  .address-section p, 
  .contact-section p, 
  .email-section p, 
  .hours-section p {
    font-size: 18px;
    margin-bottom: 0;
    text-align: left;
  }
  
  .icon {
    margin-right: 10px;
    color: #FFD700; /* Golden color for the icons */
  }
  
  .address-card h5 {
    display: flex;
    align-items: center;
  }
  
  .address-card p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  