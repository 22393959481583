.custom-btn {
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 0px;
    padding: 10px 20px;
    font-size: 16px;
    transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto; /* Center the button */
  }
  
  .custom-btn .icon {
    transition: color 0.4s ease-in-out;
  }
  
  .custom-btn:hover {
    background-color: #28a745;
    color: white;
  }
  
  .custom-btn:hover .icon {
    color: white;
  }
  
  .custom-btn:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #28a745;
    left: -100%;
    top: 0;
    transition: all 0.4s ease-in-out;
    z-index: 0;
  }
  
  .custom-btn:hover:before {
    left: 0;
  }
  
  .custom-btn span {
    position: relative;
    z-index: 1;
  }
  