/* Full-width image container */
#headerimgContainer{
  width: 98.2%;
}


.header-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  display: block;
}

/* Centered text over the image */
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 2;
}

/* Positioning for the row with the image */
.position-relative {
  position: relative;
}
