.disclosure-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .disclosure-section {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .section-title {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }
  
  .section-title:hover {
    background-color: #e0e0e0;
  }
  
  .section-content {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {
    .disclosure-container {
      padding: 10px;
    }
  
    .section-title {
      font-size: 16px;
    }
  
    .section-content {
      font-size: 14px;
    }
  }
  