.floating-whatsapp {
    position: fixed; /* Makes it stick in one position */
    bottom: 20px; /* Distance from the bottom of the screen */
    right: 20px; /* Distance from the right of the screen */
    z-index: 1000; /* Ensures it stays on top of other elements */
  }
  
  .whatsapp-button {
    border-radius: 50%; /* Makes the button circular */
    padding: 15px; /* Adjust padding for button size */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
  }
  