/* Pages/ComplaintBoardPage.css */
.complaint-board-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .complaint-board-page h1,
  .complaint-board-page h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
    margin-bottom: 20px;
  }
  
  .complaint-table, .trend-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .complaint-table th, .complaint-table td, .trend-table th, .trend-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .complaint-table th, .trend-table th {
    background-color: #3F51B5;
    color: white;
  }
  
  .complaint-table tr:nth-child(even), .trend-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .complaint-table tr:hover, .trend-table tr:hover {
    background-color: #ddd;
  }
  